<template>
    <div style="max-width: 1600px; margin: auto; position: relative;">
        <v-row class="mt-1 mb-2 d-flex align-start justify-space-between">
            <v-col cols="12">
                <h4 class="titlePages text-left font-weight-medium mb-4">Transações
                </h4>
                <p style="max-width: 50%;" class="mb-0 text-body-2 font-weight-light text-left mt-2">
                    Confira a suas transações via cartão de crédito utilizando os filtros abaixo e também selecionando a
                    operadora.
                </p>
            </v-col>
        </v-row>
        <v-card class="mt-5">
            <v-row justify="center">
                <v-col cols="12" md="8" class="">
                    <v-card-title>
                        Minhas transações
                    </v-card-title>
                    <v-card-subtitle class="text-left">
                        Selecione ao lado uma operadora para visualizar as transações
                    </v-card-subtitle>
                </v-col>
                <v-col cols="12" md="4" class="px-8 pt-9 pb-0 d-flex">
                    <!-- <span class="pt-3 pr-3 font-weight-medium">Selecione:</span> -->
                    <v-select v-model="operator" @change="" item-text="name" item-value="name" class="" dense
                        :items="configuredOperators" label="Operadora" outlined>
                    </v-select>
                </v-col>
            </v-row>
            <v-divider style="border: solid 2px;" class="blue-grey darken-1"></v-divider>
            <v-card-text class="">
                <TransectionList :operator="operator" />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import TransectionList from './TransectionList.vue'
export default {
    watch: {
        configuredOperators(v) {
            if (v.length) {
                this.operator = this.configuredOperators[0].name
            }
        },
        creditCardDialog(v) {
            console.log('setoperadora')
            if (v) {
                this.operator = this.configuredOperators[0].name
            }
        }
    },
    methods: {
        getConfiguredOperators() {
            // function que carrega o select do form
            var obj = {
                url: "/api/v1/paymentGateways/getAll",
                query: null,
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                this.configuredOperators = [...response.data];
                // this.updateConfiguredOperators(response.data)
            });
        },
    },
    mounted() {
        this.getConfiguredOperators();
    },
    data() {
        return {
            configuredOperators: [],
            operator: "",
            operatorSelected: ""
        }
    },
    props: {
        // configuredOperators: Array,
        // creditCardDialog: Boolean
    },
    components: {
        TransectionList,
    },
}
</script>

<style lang="scss" scoped></style>